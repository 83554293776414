import { SvgImage } from "../../../app/basicElements/svgImage";
import { Svg } from "../../../../enums/svg";
import { DragEvent } from "react";
import { getSvgUrl } from "../../../../functions/api/graphics/getSvgUrl";
import classNames from "classnames";
export function ImageUpload(props: {
  image: string;
  handleImageUpload: (
    image: string | ArrayBuffer,
    name: string,
    nameWithEnd: string,
  ) => void;
  type: "img" | "svg" | "pdf";
  headLine?: string;
  helpText?: string;
  svg?: Svg;
  className?: string;
}) {
  const handleFile = (file: File) => {
    if (file.size > 20971520) {
      console.log("Die Datei ist zu groß");
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      props.handleImageUpload(
        reader.result,
        file.name.split(".")[0].trim(),
        file.name,
      );
    };
    reader.readAsDataURL(file);
  };
  const handleDrop = (e: DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file) {
        if (
          (props.type === "img" &&
            file.type !== "image/png" &&
            file.type !== "image/jpeg" &&
            file.type !== "image/jpg") ||
          (props.type === "svg" && file.type !== "image/svg+xml") ||
          (props.type === "pdf" && file.type !== "application/pdf")
        ) {
          return;
        }
        handleFile(file);
      }
    }
  };
  const uploadForm = () => {
    const input = document.createElement("input");
    input.type = "file";
    switch (props.type) {
      case "svg":
        input.accept = "image/svg+xml";
        break;
      case "pdf":
        input.accept = "application/pdf";
        break;
      case "img":
      default:
        input.accept = "image/png, image/jpeg, image/jpg";
        break;
    }
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      if (file) {
        handleFile(file);
      }
    };
    input.click();
  };

  const setHeadLine = () => {
    if (props.headLine) {
      return props.headLine;
    }
    switch (props.type) {
      case "svg":
        return "SVG-Upload";
      case "img":
        return "Bild-Upload";
      case "pdf":
        return "PDF-Upload";
    }
  };

  return (
    <label className="img">
      <span className="icon">
        {setHeadLine()}
        {props.helpText ? (
          <span>{props.helpText}</span>
        ) : props.type === "img" ? (
          <span>Es können nur jpg und png-Bilddateien hochgeladen werden.</span>
        ) : null}
      </span>
      <SvgImage image={props.svg ? props.svg : Svg.figure} />
      <img
        className={classNames("icon", props.className)}
        src={props.image !== "" ? props.image : getSvgUrl(Svg.image_upload)}
        onClick={uploadForm}
        id={"uploadImage"}
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        alt={setHeadLine()}
      />
    </label>
  );
}
