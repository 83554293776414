import { useEffect, useState, HTMLProps, ReactNode } from "react";
import { getModules } from "../../../../functions/api/edit/getModules";

export enum optionsMode {
  dataprop = "dataprop",
  value = "value",
}
interface moduleSelectProps extends HTMLProps<HTMLSelectElement> {
  mode: optionsMode;
  valuesetting?: "id" | "path";
  useoptgroup?: "true" | "false";
}

export function ModuleSelect(props: moduleSelectProps) {
  const [options, setOptions] = useState<ReactNode>(null);
  useEffect(() => {
    getModules()
      .then((result) => {
        const data = result.map((module) => {
          if (props.mode === optionsMode.dataprop) {
            return (
              <option
                key={module.id}
                value={
                  !props.valuesetting || props.valuesetting === "id"
                    ? "link" + module.id
                    : module.path
                }
                data-prop={module.id}
              >
                {module.short_title.charAt(0).toUpperCase() +
                  module.short_title.slice(1)}
              </option>
            );
          } else if (props.mode === optionsMode.value) {
            return (
              <option
                key={module.id}
                value={
                  !props.valuesetting || props.valuesetting === "id"
                    ? module.id
                    : module.path
                }
                data-path={module.path}
              >
                {module.short_title.charAt(0).toUpperCase() +
                  module.short_title.slice(1)}
              </option>
            );
          }
          return (
            <option
              key={module.id}
              value={
                !props.valuesetting || props.valuesetting === "id"
                  ? "link" + module.id
                  : module.path
              }
              data-prop={module.id}
              data-path={module.path}
            >
              {module.short_title.charAt(0).toUpperCase() +
                module.short_title.slice(1)}
            </option>
          );
        });
        setOptions(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.mode, props.valuesetting]);

  return (
    <select {...props}>
      {props.children}
      {props.useoptgroup === "true" ? (
        <optgroup label="Module">{options}</optgroup>
      ) : (
        options
      )}
    </select>
  );
}
