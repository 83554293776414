import { Svg } from "../../../enums/svg";
import { ChangeEvent } from "react";
import { SvgImage } from "../../app/basicElements/svgImage";

export interface formInputInterface {
  headline: string;
  name: string;
  svg: Svg;
  type: "text" | "email" | "tel" | "password";
  required: string;
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
  element: string;
  value: string;
  disabled: string;
}

export function FormInput(props: formInputInterface) {
  return (
    <label htmlFor={props.name}>
      <span className="icon">{props.headline}</span>
      <SvgImage image={props.svg} />
      <input
        className="icon"
        type={props.type}
        id={props.name}
        name={props.name}
        onChange={props.handleInput}
        value={props.value}
        disabled={props.disabled === "true"}
      />
    </label>
  );
}
