import { postToApi } from "../../apiCommunication/apiComm";
import { apiUrls } from "../../../../enums/apiUrls";
import { basicApiResponse } from "../../../../interfaces/basicApiRespone";
import { googleCoordinatesInterface } from "../../../../elements/popups/settings/global/googleCoordinates";

export function getGoogleCoordinates(): Promise<googleCoordinatesInterface> {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.getGoogleCoordinates, {})
      .then((result: basicApiResponse) => {
        if (result.data.success) {
          resolve(result.data.result as googleCoordinatesInterface);
        } else {
          reject(result.data.error);
        }
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}
