import { ChangeEvent } from "react";
import { Svg } from "../../../enums/svg";
import { SvgImage } from "../../app/basicElements/svgImage";

export interface formTextAreaInterface {
  headline: string;
  name: string;
  svg: Svg;
  required: string;
  handleInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  element: string;
}

export function FormTextArea(props: formTextAreaInterface) {
  return (
    <label htmlFor={props.name}>
      <span className="icon">{props.headline}</span>
      <SvgImage image={props.svg} />
      <textarea
        className="icon"
        id={props.name}
        name={props.name}
        onChange={props.handleInput}
      />
    </label>
  );
}
