import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";
import { formInputInterface } from "../../../elements/htmlElements/forms/formInput";
import { formSelectInterface } from "../../../elements/htmlElements/forms/formSelect";
import { formTextAreaInterface } from "../../../elements/htmlElements/forms/formTextArea";

interface customFormApiData extends basicApiResponse {
  data: {
    success: boolean;
    result: [formInputInterface | formSelectInterface | formTextAreaInterface];
  };
}

export function getCustomForm(id: number) {
  return new Promise((resolve) => {
    postToApi(apiUrls.getCustomForm, { id: id })
      .then((data: customFormApiData) => {
        if (data.data.success) {
          resolve(data.data.result);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        console.error(e);
        resolve(false);
      });
  });
}
