import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { ChangeEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  textSizeOptions,
  textSizeOptionsAlt,
} from "../../../enums/edit/textSizes";
import { enumMap } from "../../../functions/generall/enumMap";
import { textPos } from "../../../enums/edit/textPos";
import classNames from "classnames";

export function SizeAndPos(props: {
  size: textSizeOptions | textSizeOptionsAlt;
  pos: textPos;
  mode: "text" | "etc";
  handleSizeAndPosChange: (
    size: textSizeOptions | textSizeOptionsAlt,
    pos: textPos,
  ) => void;
}) {
  const [t] = useTranslation("popups", { keyPrefix: "text" });
  const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.handleSizeAndPosChange(
      e.target.value as typeof props.size,
      props.pos,
    );
  };

  const handlePosChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.handleSizeAndPosChange(props.size, e.target.value as textPos);
  };

  const createSizeOptions = () => {
    return enumMap(
      textSizeOptions,
      (option: string, value: textSizeOptions) => {
        return (
          <div className={value} key={value}>
            <input
              type="radio"
              name="textstil"
              id={option}
              value={value}
              onChange={handleSizeChange}
              checked={props.size === value}
            />
            <label htmlFor={option} />
          </div>
        );
      },
    ) as ReactNode[];
  };

  const createSizeOptionsAlt = () => {
    return enumMap(
      textSizeOptionsAlt,
      (_option: string, value: textSizeOptionsAlt) => {
        return (
          <div className={value} key={value}>
            <input
              type="radio"
              name="textstil"
              id={value}
              value={value}
              onChange={handleSizeChange}
              checked={props.size === value}
            />
            <label htmlFor={value} />
          </div>
        );
      },
    ) as ReactNode[];
  };

  const createPosOptions = () => {
    return enumMap(textPos, (_key: string, value: textPos) => {
      return (
        <div className={value} key={value}>
          <input
            type="radio"
            name="textpos"
            id={value}
            value={value}
            onChange={handlePosChange}
            checked={props.pos === value}
          />
          <label htmlFor={value} />
        </div>
      );
    }) as ReactNode[];
  };

  return (
    <>
      <div
        className={classNames(
          "radio",
          "floatleft",
          props.mode === "etc" ? "w40" : "w30",
        )}
      >
        <span className="icon">{t("schrift_dunkel")}</span>
        <SvgImage image={Svg.color} />
        <div className="radiogroup">{createSizeOptions()}</div>
      </div>
      <div
        className={classNames(
          "radio",
          "floatleft",
          props.mode === "etc" ? "w40" : "w30",
        )}
      >
        <span className="icon">{t("schrift_hell")}</span>
        <SvgImage image={Svg.color} />
        <div className="radiogroup">{createSizeOptionsAlt()}</div>
      </div>
      <div
        className={classNames(
          "radio",
          "w20",
          props.mode === "text" ? "floatleft" : null,
        )}
      >
        <span className="icon">{t("anordnung")}</span>
        <SvgImage image={Svg.direction} />
        <div className="radiogroup">{createPosOptions()}</div>
      </div>
    </>
  );
}

//30302020
