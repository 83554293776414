import { Svg } from "../../../enums/svg";
import { ImageUpload } from "../../popups/edit/media/imageUpload";
import { useState } from "react";

export interface formImageInterface {
  headline: string;
  name: string;
  svg: Svg;
  required: string;
  handleImageUpload: (image: string, name: string) => void;
  image: string;
  element: string;
}

export function FormImage(props: formImageInterface) {
  const [image, setImage] = useState<string>(props.image);
  const handleImageUpload = (
    image: string,
    name: string,
    nameWithEnd: string,
  ) => {
    setImage(image);
    props.handleImageUpload(image, nameWithEnd);
  };
  return (
    <ImageUpload
      image={image}
      handleImageUpload={handleImageUpload}
      type={"img"}
      headLine={props.headline}
      svg={props.svg}
    />
  );
}
