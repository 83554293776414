export enum textSizeOptions {
  "xsmall" = "xsmall",
  "small" = "small",
  "standard" = "",
  "big" = "big",
  "xbig" = "xbig",
}
export enum textSizeOptionsAlt {
  "alternativexsmall" = "alternativexsmall",
  "alternativesmall" = "alternativesmall",
  "alternative" = "alternative",
  "alternativebig" = "alternativebig",
  "alternativexbig" = "alternativexbig",
}
