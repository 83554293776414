import { RefObject } from "react";

export function checkForStringOccurrences(
  text: string,
  opening: string,
  closing: string,
) {
  let openingCount = 0;
  let closingCount = 0;
  const textLength = text.length;
  for (let i = 0; i < textLength; i++) {
    if (text.substring(i, i + 2) === opening) {
      if (
        i === 0 ||
        (text.substring(i - 1, i) !== "[" && text.substring(i - 1, i) !== "(")
      ) {
        //also check if current position is the first position
        if (i === 0) {
          openingCount++;
        }
        openingCount++;
      }
    }
    if (text.substring(i, i + 2) === closing) {
      if (
        i === textLength - 2 ||
        (text.substring(i + 2, i + 3) !== "]" &&
          text.substring(i + 2, i + 3) !== ")")
      ) {
        //also check if current position is the last position
        if (i === textLength - 2) {
          closingCount++;
        }
        closingCount++;
      }
    }
  }
  if (openingCount > closingCount) {
    return "opening";
  }
  if (openingCount < closingCount) {
    return "closing";
  }
  return "none";
}

function removeUnneeded(text: string, opening: string, closing: string) {
  text = text.replaceAll(opening + opening, "");
  text = text.replaceAll(closing + closing, "");
  text = text.replaceAll(closing + opening, "");
  text = text.replaceAll(closing + " " + closing, " ");
  return text;
}

//insert [[ ]] around selected text
export function formatSelectedText(
  textComponent: RefObject<HTMLTextAreaElement>,
  opening: string,
  closing: string,
) {
  let selectedText: string;
  const startPos = textComponent.current.selectionStart;
  const endPos = textComponent.current.selectionEnd;
  selectedText = textComponent.current.value.substring(startPos, endPos);
  if (selectedText === "") {
    return;
  }
  //if text starts with [[ and ends with ]] remove [[ and ]] and return
  if (
    selectedText.substring(0, 2) === opening &&
    selectedText.substring(selectedText.length - 2, selectedText.length) ===
      closing
  ) {
    selectedText = selectedText.replaceAll(opening, "");
    selectedText = selectedText.replaceAll(closing, "");
    textComponent.current.value = removeUnneeded(
      textComponent.current.value.substring(0, startPos) +
        selectedText +
        textComponent.current.value.substring(
          endPos,
          textComponent.current.value.length,
        ),
      opening,
      closing,
    );
    return;
  }

  const occurrences = checkForStringOccurrences(selectedText, opening, closing);
  //if opening more often than closing remove all opening and add [[ to the start of the text
  if (occurrences === "opening") {
    selectedText = selectedText.replaceAll(opening, "");
    textComponent.current.value = removeUnneeded(
      textComponent.current.value.substring(0, startPos) +
        opening +
        selectedText +
        textComponent.current.value.substring(
          endPos,
          textComponent.current.value.length,
        ),
      opening,
      closing,
    );
    return;
  }
  //if closing more often than opening remove all closing and add ]] to the end of the text
  if (occurrences === "closing") {
    selectedText = selectedText.replaceAll(closing, "");
    textComponent.current.value = removeUnneeded(
      textComponent.current.value.substring(0, startPos) +
        selectedText +
        closing +
        textComponent.current.value.substring(
          endPos,
          textComponent.current.value.length,
        ),
      opening,
      closing,
    );
    return;
  }
  //if no occurrence check if really no occurrence or just same amount of opening and closing
  if (occurrences === "none") {
    const openingCount = selectedText.includes(opening);
    const closingCount = selectedText.includes(closing);
    if (openingCount === false && closingCount === false) {
      //insert [( )] around selected text
      textComponent.current.value =
        textComponent.current.value.substring(0, startPos) +
        opening +
        selectedText +
        closing +
        textComponent.current.value.substring(
          endPos,
          textComponent.current.value.length,
        );
    } else {
      //there are some occurrences so delete them
      selectedText = selectedText.replaceAll(opening, "");
      selectedText = selectedText.replaceAll(closing, "");
      textComponent.current.value =
        textComponent.current.value.substring(0, startPos) +
        selectedText +
        textComponent.current.value.substring(
          endPos,
          textComponent.current.value.length,
        );
    }
  }
}
//remove all [[ ]], [( )] and (( )) from text
export function removeAllFormats(text: string) {
  text = text.replaceAll("[[", "");
  text = text.replaceAll("]]", "");
  text = text.replaceAll("((", "");
  text = text.replaceAll("))", "");
  text = text.replaceAll("[(", "");
  text = text.replaceAll(")]", "");
  return text;
}
