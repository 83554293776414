export function removeSpecialChars(str: string) {
  //replace ä with ae
  str = str.replace(/ä/g, "ae");
  //replace ö with oe
  str = str.replace(/ö/g, "oe");
  //replace ü with ue
  str = str.replace(/ü/g, "ue");
  //replace ß with ss
  str = str.replace(/ß/g, "ss");
  //replace all special chars with -
  str = str.replace(/[^a-zA-Z0-9]/g, "-");
  //replace all multiple - with single -
  str = str.replace(/-+/g, "-");
  //remove - from start and end
  str = str.replace(/^-|-$/g, "");
  return str;
}
