import { postToApi } from "../apiCommunication/apiComm";
import { apiUrls } from "../../../enums/apiUrls";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";

export function sendContactForm(
  data: object,
  formNumber: number,
  file?: Blob,
  fileName?: string,
  progressBar?: (progress: number) => void,
) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("contactFormData", JSON.stringify(data));
    formData.append("formNumber", formNumber.toString());
    if (file !== undefined) {
      formData.append("file", file);
      formData.append("hasFile", "true");
      formData.append("fileName", fileName);
    } else {
      formData.append("hasFile", "false");
    }

    postToApi(apiUrls.sendContactForm, formData, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (progressBar !== undefined) {
          progressBar(
            Math.round((progressEvent.loaded / progressEvent.total) * 100),
          );
        }
      },
    })
      .then((result: basicApiResponse) => {
        if (result.data.success === false) {
          reject(result.data.error);
        }
        resolve(result.data.result);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
