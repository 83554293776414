import { Svg } from "../../../enums/svg";
import { ChangeEvent } from "react";
import { SvgImage } from "../../app/basicElements/svgImage";

export interface formSelectInterface {
  headline: string;
  name: string;
  svg: Svg;
  required: string;
  handleInput: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: { text: string; value: string }[];
  element: string;
}

export function FormSelect(props: formSelectInterface) {
  return (
    <label htmlFor={props.name}>
      <span className="icon">{props.headline}</span>
      <SvgImage image={props.svg} />
      <select
        className="icon"
        id={props.name}
        name={props.name}
        onChange={props.handleInput}
      >
        {props.options.map((option) => (
          <option key={option.text} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </label>
  );
}
