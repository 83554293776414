import PopupWrapper from "../popupWrapper";
import {
  ChangeEvent,
  createElement,
  HTMLProps,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { getCustomForm } from "../../../functions/api/page/getCustomForm";
import {
  FormInput,
  formInputInterface,
} from "../../htmlElements/forms/formInput";
import {
  FormSelect,
  formSelectInterface,
} from "../../htmlElements/forms/formSelect";
import {
  FormTextArea,
  formTextAreaInterface,
} from "../../htmlElements/forms/formTextArea";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { sendContactForm } from "../../../functions/api/page/sendContactForm";
import {
  FormImage,
  formImageInterface,
} from "../../htmlElements/forms/formImage";
import { Image as imageJs } from "image-js";
import { useFeedback } from "../../../functions/elements/useFeedback";
import { delay } from "lodash";
import { FormText, formTextInterface } from "../../htmlElements/forms/formText";

export enum customFormInputTypes {
  input = "input",
  textarea = "textarea",
  select = "select",
  image = "image",
  text = "text",
}

const customFormElements = {
  [customFormInputTypes.input]: FormInput,
  [customFormInputTypes.textarea]: FormTextArea,
  [customFormInputTypes.select]: FormSelect,
  [customFormInputTypes.image]: FormImage,
  [customFormInputTypes.text]: FormText,
};

export interface customFormDataType {
  data: (
    | formInputInterface
    | formSelectInterface
    | formTextAreaInterface
    | formImageInterface
    | formTextInterface
  )[];
}

export default function CustomForm(props: {
  handleClosing: () => void;
  customFormNumber: number;
  headline?: string;
}) {
  const [customForm, setCustomForm] = useState<customFormDataType>(null);
  const [formContentData, setFormContentData] = useState<{
    [key: string]: string;
    imageRequired: string;
  }>({ imageRequired: "false" });
  const [formContentDataImage, setFormContentDataImage] = useState<{
    image: string;
    name: string;
    isImage: boolean;
  }>({ image: "", name: "", isImage: false });
  const feedback = useFeedback();
  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setFormContentData({
      ...formContentData,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleImageUpload = (image: string, name: string) => {
    setFormContentDataImage({
      image: image,
      name: name,
      isImage: true,
    });
  };
  const customFormCreator = (daten: customFormDataType): ReactNode => {
    if (daten === null) {
      return null;
    }
    const elems: ReactNode[] = [];
    const initialFormContentData = {
      imageRequired: "false",
    };
    daten.data.forEach((element, i) => {
      if (typeof element["element"] === "undefined") {
        return;
      }

      element["key"] = props.customFormNumber + element["element"] + i;
      if (
        (element["element"] as customFormInputTypes) ===
        customFormInputTypes.image
      ) {
        element["handleImageUpload"] = handleImageUpload;
      } else if (element["element"] !== customFormInputTypes.text) {
        element["handleInput"] = handleInput;
      }
      elems.push(
        jsonToHTML(element["element"], element as unknown as HTMLProps<never>),
      );
      switch (element["element"] as customFormInputTypes) {
        case customFormInputTypes.image:
          if (element["required"] === "true") {
            initialFormContentData["imageRequired"] = "true";
          }
          break;
        case customFormInputTypes.select:
          initialFormContentData[element["name"]] = element["options"][0].value;
          break;
        case customFormInputTypes.text:
          break;
        default:
          if (typeof element["value"] !== "undefined") {
            initialFormContentData[element["name"]] = element["value"];
          } else {
            initialFormContentData[element["name"]] = "";
          }
          break;
      }
    });
    if (Object.keys(formContentData).length === 1) {
      setFormContentData(initialFormContentData);
    }
    return elems;
  };
  const jsonToHTML = (
    element: string,
    daten: HTMLProps<never>,
    children?: ReactNode[],
  ) => {
    if (typeof customFormElements[element] !== "undefined") {
      return createElement(customFormElements[element], daten, children);
    } else {
      return null;
    }
  };
  useEffect(() => {
    getCustomForm(props.customFormNumber)
      .then((res: customFormDataType) => {
        setCustomForm(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.customFormNumber]);

  const sendForm = () => {
    let missingData = false;
    customForm.data.forEach((element) => {
      if (element["element"] === customFormInputTypes.text) {
        return;
      }
      if (typeof element["required"] !== "undefined") {
        if (element["required"] === "true") {
          if (
            typeof formContentData[element["name"]] === "undefined" ||
            formContentData[element["name"]].trim() === ""
          ) {
            missingData = true;
          }
        }
      }
    });
    if (
      formContentData.imageRequired === "true" &&
      formContentDataImage.isImage === false
    ) {
      missingData = true;
    }
    if (missingData) {
      return;
    }
    const dataToSend = formContentData;
    delete dataToSend.imageRequired;
    feedback.showLoading();
    if (formContentDataImage.image !== "") {
      imageJs
        .load(formContentDataImage.image)
        .then((image) => {
          image
            .toBlob()
            .then((blob) => {
              sendContactForm(
                dataToSend,
                props.customFormNumber,
                blob,
                formContentDataImage.name,
              )
                .then((res: null | { headline: string; text: string }) => {
                  if (res !== null) {
                    feedback.showAutoresponder(
                      res.headline,
                      res.text,
                      props.handleClosing,
                    );
                  } else {
                    feedback.showSuccess();
                    delay(() => {
                      props.handleClosing();
                    }, 500);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  feedback.hideFeedback();
                });
            })
            .catch((err) => {
              console.error(err);
              feedback.hideFeedback();
            });
        })
        .catch((err) => {
          console.error(err);
          feedback.hideFeedback();
        });
    } else
      sendContactForm(dataToSend, props.customFormNumber)
        .then((res: null | { headline: string; text: string }) => {
          if (res !== null) {
            feedback.showAutoresponder(
              res.headline,
              res.text,
              props.handleClosing,
            );
          } else {
            feedback.showSuccess();
            delay(() => {
              props.handleClosing();
            }, 500);
          }
        })
        .catch((err) => {
          console.error(err);
          feedback.hideFeedback();
        });
  };

  const createForm = () => {
    return customFormCreator(customForm);
  };

  return feedback.feedbackState.show === "inactive" ? (
    <PopupWrapper>
      <div id="contactform" className="medium">
        <span>{props.headline ? props.headline : "Kontaktformular"}</span>
        <div className="container">
          <hr className="half" />
          {createForm()}
          <hr />
        </div>
        <button id="rt" className="jsclose" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        <button id="rb" className="jssend" onClick={sendForm}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  ) : (
    feedback.currentFeedback()
  );
}
