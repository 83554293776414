export function convertEditorTextToText(text: string) {
  let textDb = text.replace(new RegExp("\\[\\(", "g"), "<span>");
  textDb = textDb.replace(new RegExp("\\[\\[", "g"), "<strong>");
  textDb = textDb.replace(new RegExp("\\)]", "g"), "</span>");
  textDb = textDb.replace(new RegExp("]]", "g"), "</strong>");
  return clickableLinks(textDb);
}

const getFromBetween = {
  results: [],
  string: "",
  getFromBetween: function (sub1: string, sub2: string) {
    if (
      getFromBetween.string.indexOf(sub1) < 0 ||
      getFromBetween.string.indexOf(sub2) < 0
    ) {
      return false;
    }
    const SP = getFromBetween.string.indexOf(sub1) + sub1.length;
    const string1 = getFromBetween.string.substring(0, SP);
    const string2 = getFromBetween.string.substring(SP);
    const TP = string1.length + string2.indexOf(sub2);
    return getFromBetween.string.substring(SP, TP);
  },
  removeFromBetween: function (sub1: string, sub2: string) {
    if (
      getFromBetween.string.indexOf(sub1) < 0 ||
      getFromBetween.string.indexOf(sub2) < 0
    ) {
      return false;
    }
    const removal = sub1 + getFromBetween.getFromBetween(sub1, sub2) + sub2;
    getFromBetween.string = getFromBetween.string.replace(removal, "");
    return true;
  },
  getAllResults: function (sub1: string, sub2: string) {
    // first check to see if we do have both substrings
    if (
      getFromBetween.string.indexOf(sub1) < 0 ||
      getFromBetween.string.indexOf(sub2) < 0
    ) {
      return;
    }

    // find one result
    const result = getFromBetween.getFromBetween(sub1, sub2);
    // push it to the results array
    getFromBetween.results.push(result);
    // remove the most recently found one from the string
    getFromBetween.removeFromBetween(sub1, sub2);

    // if there's more substrings
    if (
      getFromBetween.string.indexOf(sub1) > -1 &&
      getFromBetween.string.indexOf(sub2) > -1
    ) {
      getFromBetween.getAllResults(sub1, sub2);
    }
  },
  get: function (string: string, sub1: string, sub2: string): string[] {
    getFromBetween.results = [];
    getFromBetween.string = string;
    getFromBetween.getAllResults(sub1, sub2);

    return getFromBetween.results;
  },
};
function clickableLinks(text: string) {
  const innerText = getFromBetween.get(text, "((", "))");
  const elementHtml = {
    start: {
      tel: '<a href="tel:',
      mail: '<a href="mailto:',
    },
    ending: "</a>",
  };
  for (let i = 0; i < innerText.length; i++) {
    if (innerText[i].indexOf("@") !== -1) {
      text = text.replace(
        "((" + innerText[i] + "))",
        elementHtml.start.mail +
          innerText[i] +
          '">' +
          innerText[i] +
          elementHtml.ending,
      );
    }
    if (innerText[i].indexOf("+") !== -1) {
      text = text.replace(
        "((" + innerText[i] + "))",
        elementHtml.start.tel +
          innerText[i] +
          '">' +
          innerText[i] +
          elementHtml.ending,
      );
    }
  }
  return text;
}
