import { SvgImage } from "../../../app/basicElements/svgImage";
import { Svg } from "../../../../enums/svg";
import { ChangeEvent } from "react";

export function ImageRadius(props: {
  value: string;
  handleRadiusChange: (radius: string) => void;
  className?: string;
}) {
  const handleRadiusChange = (event: ChangeEvent<HTMLSelectElement>) => {
    props.handleRadiusChange(event.target.value);
  };
  return (
    <label
      htmlFor="uploadRadius"
      className={
        typeof props.className !== "undefined" ? props.className : "w50"
      }
    >
      <span className="icon">Form & Schatten</span>
      <SvgImage image={Svg.radius} />
      <select
        id="uploadRadius"
        className="icon"
        onChange={handleRadiusChange}
        value={props.value}
      >
        <option value="radstandard">Rechteckig</option>
        <option value="radstandardshadow">Rechteckig schattiert</option>
        <option value="radrund">Rund</option>
        <option value="radrundshadow">Rund schattiert</option>
        <option value="radfull">Randlos</option>
        <option value="radfullshadow">Randlos schattiert</option>
        <option value="radfulltop">Randlos oben</option>
        <option value="radfullbottom">Randlos unten</option>
      </select>
    </label>
  );
}
