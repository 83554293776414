export enum gridPosY {
  "Mitte" = "middle",
  "Oben" = "start",
  "Unten" = "end",
}

export enum gridPosX {
  "Mittig" = "center",
  "Links" = "left",
  "Rechts" = "right",
}
