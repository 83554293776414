import { apiUrls } from "../../../enums/apiUrls";
import { postToApi } from "../apiCommunication/apiComm";
import { apiResponses } from "../../../../backend/enums/apiResponses";
import { pageListDataInterface } from "../../../interfaces/settings/pageListDataInterface";
import { basicApiResponse } from "../../../interfaces/basicApiRespone";

interface getPageListReturnDataInterface extends basicApiResponse {
  data: {
    result: pageListDataInterface[];
    success: boolean;
    error?: apiResponses;
  };
}

export function getPageListData(): Promise<pageListDataInterface[]> {
  return new Promise((resolve, reject) => {
    postToApi(apiUrls.getPageListData, {})
      .then((data: getPageListReturnDataInterface) => {
        if (data.data.success === false) {
          reject(data.data.error);
        }
        resolve(data.data.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
