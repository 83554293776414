import { imageUploadFormats } from "../../enums/edit/imageUploadFormats";

export function calculateDimension(
  imageWi: number,
  height: number,
  format: string,
  newDimension: number,
) {
  switch (
    format as imageUploadFormats //todo formats can be removed
  ) {
    case imageUploadFormats.quadrat:
      return { width: newDimension, height: newDimension };
    case imageUploadFormats.breitformat16zu9:
      return { width: newDimension, height: (newDimension / 16) * 9 };
    case imageUploadFormats.hochformat3zu4:
      return { width: newDimension, height: (newDimension / 3) * 4 };
    case imageUploadFormats.original:
      return {
        width: newDimension,
        height: (newDimension / imageWi) * height,
      };
    case imageUploadFormats.panorama3zu1:
      return { width: newDimension, height: newDimension / 3 };
    case imageUploadFormats.panorama5zu1:
      return { width: newDimension, height: newDimension / 5 };
    case imageUploadFormats.querformat4zu3:
      return { width: newDimension, height: (newDimension / 4) * 3 };
    default:
      return {
        width: newDimension,
        height: (newDimension / imageWi) * height,
      };
  }
}
