export enum fixedCellCount {
  "Box mit 1 Zelle" = "x1",
  "Box mit 2 Zellen nebeneinander" = "x2",
  "Box mit 3 Zellen nebeneinander" = "x3",
  "Box mit 4 Zellen nebeneinander" = "x4",
  "Box mit 6 Zellen nebeneinander" = "x6",
  "Box mit 8 Zellen nebeneinander" = "x8",
}

export enum flexCellCount {
  "Box mit 2 Zellen (dynamisch)" = "x2d",
  "Box mit 2 Zellen (2 zu 1)" = "x2l",
  "Box mit 2 Zellen (1 zu 2)" = "x2r",
  "Box mit 2 Zellen (3 zu 2)" = "x3l",
  "Box mit 2 Zellen (2 zu 3)" = "x3r",
  "Box mit 2 Zellen (3 zu 1)" = "x4l",
  "Box mit 2 Zellen (1 zu 3)" = "x4r",
}
