import { Svg } from "../../../../enums/svg";
import { DragEvent } from "react";
import { SvgImage } from "../../../app/basicElements/svgImage";
import { getSvgUrl } from "../../../../functions/api/graphics/getSvgUrl";

export function VideoUpload(props: {
  video: string;
  handleVideoUpload: (video: string | ArrayBuffer, name: string) => void;
  headLine?: string;
  helpText?: string;
  svg?: Svg;
}) {
  const handleFile = (file: File) => {
    if (file) {
      if (file.type !== "video/mp4") {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        props.handleVideoUpload(reader.result, file.name.split(".")[0].trim());
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDrop = (e: DragEvent<HTMLImageElement | HTMLVideoElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length > 0) {
      handleFile(e.dataTransfer.files[0]);
    }
  };
  const uploadForm = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/mp4";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      if (file) {
        handleFile(file);
      }
    };
    input.click();
  };

  const setHeadLine = () => {
    if (props.headLine) {
      return props.headLine;
    }
    return "Video hochladen";
  };

  return (
    <label htmlFor="UploadVideo" className="img nomobile">
      <span className="icon">
        {setHeadLine()}
        {props.helpText ? <span>{props.helpText}</span> : null}
      </span>
      <SvgImage image={props.svg ? props.svg : Svg.figure} />
      {props.video === "" ? (
        <img
          className="icon"
          src={getSvgUrl(Svg.image_upload)}
          onClick={uploadForm}
          id={"uploadVideo"}
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          alt={setHeadLine()}
        />
      ) : (
        <video
          className="icon"
          src={props.video}
          onClick={uploadForm}
          id={"uploadVideo"}
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          controls={false}
          autoPlay={true}
          muted={true}
          loop={true}
        />
      )}
    </label>
  );
}
