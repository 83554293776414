import { elementEditorModes } from "../../../enums/edit/elementEditorModes";
import { oListInterface } from "../../../interfaces/elements/oListInterface";
import { useState, ChangeEvent, ReactNode } from "react";
import { animationInterface } from "../../../interfaces/animations/animationInterface";
import { produce } from "immer";
import {
  textSizeOptions,
  textSizeOptionsAlt,
} from "../../../enums/edit/textSizes";
import { textPos } from "../../../enums/edit/textPos";
import { TextEditor } from "./textEditor";
import { SizeAndPos } from "./sizeAndPos";
import { AnimationsSelect } from "./selects/animationsSelect";
import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { convertEditorTextToText } from "../../../functions/elements/convertEditorText";
import DOMPurify from "dompurify";
import PopupWrapper from "../popupWrapper";
import { paddingOptions } from "../../../enums/edit/paddingOptions";
import { enumMap } from "../../../functions/generall/enumMap";

export default function OListEditor(props: {
  elementData: oListInterface;
  mode: elementEditorModes;
  handleElementSaving: (elementData: oListInterface) => void;
  handleClosing?: () => void;
  handleDelete?: () => void;
}) {
  const [editorState, setEditorState] = useState<oListInterface>({
    ...props.elementData,
    editorText: props.elementData.editorText
      ? props.elementData.editorText
      : props.elementData.text,
    padding: props.elementData.padding
      ? props.elementData.padding
      : paddingOptions.paddingboth,
  });

  const handleAnimationChange = (data: animationInterface[]) => {
    setEditorState(
      produce((state) => {
        state.animations = data;
      }),
    );
  };

  const setEditorValue = (value: string) => {
    setEditorState(
      produce((state) => {
        state.editorText = value;
      }),
    );
  };

  const handleSizeAndPosChange = (
    size: textSizeOptions | textSizeOptionsAlt,
    pos: textPos,
  ) => {
    setEditorState(
      produce((state) => {
        state.sizeAndPos.size = size;
        state.sizeAndPos.pos = pos;
      }),
    );
  };

  const handleEditorInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setEditorState(
      produce((state) => {
        state.editorText = e.target.value;
      }),
    );
  };

  const handleElementSaving = () => {
    const text = convertEditorTextToText(
      DOMPurify.sanitize(editorState.editorText),
    );
    //generate listItems from text
    props.handleElementSaving({
      ...editorState,
      listItems: text.split("\n").map((item) => item.trim()),
    });
  };

  const handlePaddingChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEditorState(
      produce((state) => {
        state.padding = e.target.value as paddingOptions;
      }),
    );
  };

  const createPaddingOptions = () => {
    return enumMap(paddingOptions, (_key: string, value: paddingOptions) => {
      return (
        <div className={_key} key={_key}>
          <input
            type="radio"
            name="padding"
            id={_key}
            value={value}
            onChange={handlePaddingChange}
            checked={editorState.padding === value}
          />
          <label htmlFor={_key} />
        </div>
      );
    }) as ReactNode[];
  };

  return (
    <PopupWrapper>
      <div id="olist" className="xxxlarge">
        <span>Aufzählung bearbeiten</span>
        <div className="container">
          <hr className="half" />
          <TextEditor
            editorState={editorState}
            headline={"Aufzählung"}
            helpText={"Ein Aufzählungspunkt pro Zeile."}
            handleEditorInput={handleEditorInput}
            setEditorValue={setEditorValue}
          />
          <SizeAndPos
            pos={editorState.sizeAndPos.pos}
            size={editorState.sizeAndPos.size}
            mode={"text"}
            handleSizeAndPosChange={handleSizeAndPosChange}
          />
          <div className="radio w20">
            <span className="icon">Abstand</span>
            <SvgImage image={Svg.padding} />
            <div className="radiogroup">{createPaddingOptions()}</div>
          </div>
          <AnimationsSelect
            data={editorState.animations}
            handleAnimationChange={handleAnimationChange}
          />
          <hr />
        </div>
        <button id="rt" onClick={props.handleClosing}>
          <SvgImage image={Svg.delete} />
        </button>
        {props.mode === elementEditorModes.edit && (
          <button id="lb" onClick={props.handleDelete}>
            <SvgImage image={Svg.trash} />
          </button>
        )}
        <button id="rb" onClick={handleElementSaving}>
          <SvgImage image={Svg.check} />
        </button>
      </div>
    </PopupWrapper>
  );
}
