import { SvgImage } from "../../app/basicElements/svgImage";
import { Svg } from "../../../enums/svg";
import { ChangeEvent, useRef } from "react";
import {
  formatSelectedText,
  removeAllFormats,
} from "../../../functions/generall/textFormatting";

interface editorState {
  editorText: string;
}
export function TextEditor(props: {
  editorState: editorState;
  headline: string;
  helpText: string;
  handleEditorInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  setEditorValue: (value: string) => void;
}) {
  const ref = useRef<HTMLTextAreaElement>(null);
  // todo add bold, highlight, link, delete functionality
  const handleBold = () => {
    formatSelectedText(ref, "[[", "]]");
    props.setEditorValue(ref.current.value);
  };
  const handleSpan = () => {
    formatSelectedText(ref, "[(", ")]");
    props.setEditorValue(ref.current.value);
  };
  const handleLink = () => {
    formatSelectedText(ref, "((", "))");
    props.setEditorValue(ref.current.value);
  };

  const handleDeleteFormats = () => {
    props.setEditorValue(removeAllFormats(ref.current.value));
  };

  return (
    <>
      <label htmlFor="platzhalterText">
        <span className="icon">
          {props.headline}
          <span>{props.helpText}</span>
        </span>
        <div className="editicons">
          <span className="span" onClick={handleSpan}>
            <SvgImage image={Svg.bold} />
          </span>
          <span className="strong" onClick={handleBold}>
            <SvgImage image={Svg.highlight} />
          </span>
          <span className="link" onClick={handleLink}>
            <SvgImage image={Svg.function} />
          </span>
          <span className="delete" onClick={handleDeleteFormats}>
            <SvgImage image={Svg.delete} />
          </span>
        </div>
        <SvgImage image={Svg.edit} />
        <textarea
          ref={ref}
          className="icon"
          id="platzhalterText"
          placeholder=""
          onChange={props.handleEditorInput}
          value={props.editorState.editorText}
        />
      </label>
    </>
  );
}
