export interface formTextInterface {
  headline?: string;
  required?: string;
  element: string;
}

export function FormText(props: formTextInterface) {
  return (
    <>
      <hr />
      <p className={"info"}>{props.headline}</p>
    </>
  );
}
